import React, { createContext, useState } from 'react'

const SiteContext = createContext({
  splash: {},
  transition: {},
  lightbox: {}
})

export const SiteProvider = ({ children }) => {
  const [lightboxImageSrc, setLightboxImageSrc] = useState(null)
  const [pageTransition, setPageTransition] = useState(false)
  const [splashPlayed, setSplashPlayed] = useState(false)

  const contextValue = {
    splash: { splashPlayed, setSplashPlayed },
    lightbox: { lightboxImageSrc, setLightboxImageSrc },
    transition: { pageTransition, setPageTransition }
  }

  return (
    <SiteContext.Provider value={contextValue}>
      {children}
    </SiteContext.Provider>
  )
}

export default SiteContext
